import React, { useState } from "react";
import { foods } from "../data/food.data";
import { MENUS } from "../data/menu.data";
import { Food } from "./Food";

export const Menu = () => {
  const menus = MENUS;
  const breakfasts = foods.filter((food) => food.type === "breakfast");
  const [foodMenu, setFoodMenu] = useState(breakfasts);
  const [filter, setFilter] = useState("breakfast");
  const filterMenu = (menuFilter) => {
    const newFoods =
      menuFilter === "all"
        ? foods
        : foods.filter((food) => food.type === menuFilter);
    setFoodMenu(newFoods);
    setFilter(menuFilter);
  };

  // set the initial data to breakfast
  // filterMenu("breakfast");

  return (
    <section className="menu-area section-gap">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="menu-content col-lg-8">
            <div className="title text-center">
              <h1 id="menu" className="mb-10">
                What kind of Foods we serve for you
              </h1>
              <p>We serve fresh and delicate Ethio-Eritrean food menus. Come and visit our restaurant</p>
            </div>
          </div>
        </div>

        <ul className="filter-wrap filters filters-menu col-lg-12 no-padding">
          {menus.map((menu) => {
            return (
              <li
                key={menu.id}
                className={filter === menu.id ? "active" : ""}
                onClick={() => {
                  filterMenu(menu.id);
                }}
              >
                {menu.name}
              </li>
            );
          })}
        </ul>

        <div className="filters-content">
          <div className="row grid grid-menu">
            {foodMenu.map((food) => (
              <Food key={food.name} food={food} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
