import React from "react";

export const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="footer-widget-wrap">
        <div className="container">
          <div className="row section-gap">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h4>Opening Hours</h4>
                <ul className="hr-list">
                  <li className="d-flex justify-content-between">
                    <span>Monday - Friday</span>
                    <span>08.00 am - 10.00 pm</span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>Saturday & Sunday</span>{" "}
                    <span>08.00 am - 10.00 pm</span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>Tuesday</span> <span>NO WORK</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h4>Contact Us</h4>

                <div className="single-contact-address d-flex flex-row">
                  <div className="icon mr-2">
                    <span class="lnr lnr-map-marker"></span>
                  </div>
                  <p>
                    Seattle, WA <br />
                    10515 Greenwood Ave N, Seattle, Washington <br />
                  </p>
                </div>

                <div className="single-contact-address d-flex flex-row">
                  <div className="icon mr-2">
                    <span class="lnr lnr-envelope"></span>
                  </div>
                  <a href="mailto:info@adulisrestaurant.com">
                    info@adulisrestaurant.com
                  </a>
                  <br />
                </div>
                <div className="single-contact-address d-flex flex-row">
                  <div className="icon mr-2">
                    <span class="lnr lnr-laptop-phone"></span>
                  </div>
                  <a href="https://www.adulisrestaurant.com">
                    www.adulisrestaurant.com
                  </a>
                  <br />
                  <br />
                </div>
                <div className="single-contact-address d-flex flex-row">
                  <div className="icon mr-2">
                    <span class="lnr lnr-phone"></span>
                  </div>
                  <a href="tel:+1 206 920 5973" className="number">
                    +1 206 920 5973
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h4>Newsletter</h4>
                <p>Subscribe for our Weekly Newsletters</p>
                <div className="d-flex flex-row" id="mc_embed_signup">
                  <form>
                    <div className="input-group add-on align-items-center d-flex">
                      <input
                        className="form-control"
                        name="EMAIL"
                        placeholder="Your Email address"
                        required=""
                        type="email"
                      />
                      <div style={{ position: "absolute", left: "-5000px" }}>
                        <input
                          name="b_36c4fd991d266f23781ded980_aefe40901a"
                          tabIndex={-1}
                          type="text"
                        />
                      </div>
                      <div className="input-group-btn">
                        <button className="genric-btn">
                          <span className="lnr lnr-arrow-right"></span>
                        </button>
                      </div>
                    </div>
                    <div className="info mt-20"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
