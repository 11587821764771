import React from "react";

export const Reservation = () => {
  return (
    <section className="reservation-area section-gap relative mb-5">
      <div className="overlay overlay-bg"></div>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 reservation-left">
            <h1 className="text-white">
              Reserve Your Seats to Confirm if You Come with Your Family
            </h1>
          </div>
          <div className="col-lg-5 reservation-right">
            <form className="form-wrap text-center" action="#">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Your Name"
              />
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Your Email Address"
              />
              <input
                type="text"
                className="form-control"
                name="phone"
                placeholder="Phone Number"
              />
              <input
                type="text"
                className="form-control date-picker"
                name="date"
                placeholder="Select Date & time"
              />
              <div className="form-select" id="service-select">
                {/* <select>
                  <option data-display="">Select Event</option>
                  <option value="1">Event One</option>
                  <option value="2">Event Two</option>
                  <option value="3">Event Three</option>
                  <option value="4">Event Four</option>
                </select> */}
              </div>
              <button className="primary-btn text-uppercase mt-20">
                Make Reservation
              </button>
              <br />
              <br />
              <h1> COMING SOON!! </h1>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
