export const MENUS = [
    {
        id: 'all',
        name: 'All Menu'
    },
    {
        id: 'breakfast',
        name: 'Breakfast'
    },
    {
        id: 'appetizers',
        name: 'Appetizers'
    },
    {
        id: 'tibsentree',
        name: 'Tibs Entrees'
    },
    {
        id: 'lambentree',
        name: 'Lamb Entrees'
    },
    {
        id: 'tradstews',
        name: 'Traditional Stews'
    },
    {
        id: 'seafood',
        name: 'Sea Foods'
    },
    {
        id: 'veganentree',
        name: 'Vegan Entrees'
    },
    {
        id: 'pasta',
        name: 'Adulis Pasta'
    },
    {
        id: 'sandwich',
        name: 'Sandwiches'
    },
    {
        id: 'firfir',
        name: 'Firfir Entrees'
    },
];