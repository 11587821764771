export const foods = [
  {
    name: "Scrambled Eggs",
    description: "Fast scrambled with onion, tomatoes, garlic and jalapeno",
    price: "$11.95",
    type: "breakfast",
  },
  {
    name: "Lamb Tibs",
    description:
      "lamb meat sauteed with onion, tomatoes, red pepper chutney and clearfied butter, awaze",
    price: "$21.95",
    type: "lambentree",
  },
  {
    name: "Foul",
    description:
      "slow cooked with onion garlic, tomatoes cumin topped with feta cheese and olive oil",
    price: "$13.50",
    type: "breakfast",
  },
  {
    name: "Alicha Lamb Tibs",
    description:
      "lamb meat sauteed with onion, garlic, ginger tumeric and fresh tomatoes",
    price: "$ 20.95",
    type: "lambentree",
  },
  {
    name: "Kitcha Fit Fit",
    description:
      "homemade flatbread broken into small pieces blended with berbere and clarified butter, also Vegan version topped with tomatoes, onion jalapeno",
    price: "$15",
    type: "breakfast",
  },
  {
    name: "Yebeg Kikil(Roasted Lamb)",
    description: "",
    price: "$ 21.99",
    type: "lambentree",
  },
  {
    name: "Kategna",
    description:
      'Traditional flat bread "injera" toasted with chill peppers and clarified butter',
    price: "$8",
    type: "tibsentree",
  },
  {
    name: "Beef Alicha",
    description: "",
    price: "$ 18.99",
    type: "lambentree",
  },
  {
    name: "Alicha tibs",
    description:
      "traditional cut steak fried with onions,garlic,ginger tumeric and fresh tomatoes",
    price: "$20.95",
    type: "tibsentree",
  },
  {
    name: "Keywot beef",
    description: "",
    price: "$ 18.99",
    type: "lambentree",
  },
  {
    name: "Veggie roll",
    description:
      "Spicy red lentils,yellow lentils,collard greens,split peas rolled with traditional flat bread injera",
    price: "$7.99",
    type: "appetizers",
  },
  {
    name: "Sambusa",
    description: "Normal . with beef . with chicken ",
    price: "$3/ $4/ $4",
    type: "appetizers",
  },
  {
    name: "Zilzil tibs",
    description:
      "traditional cut steak fried with onions, green chillers, rosmary",
    price: "$21.95",
    type: "tibsentree",
  },
  {
    name: "Salata",
    description: "omatoes,jalapeno,red onions served with Adulis dressing",
    price: "$7.5",
    type: "appetizers",
  },
  {
    name: "Gored Gored",
    description:
      "bite size beef lightly sauteed with clearfield butter and adulis hot pepper mitmita",
    price: "$21.95",
    type: "tibsentree",
  },
  {
    name: "Asa Gulash",
    description: "talapia sauteed with onion, garlic, tomatoes and awaze sauce",
    price: "$20.95",
    type: "seafood",
  },
  {
    name: "Derek Tibs",
    description:
      "rib eye steak hard fried with onion, garlic, ginger, jalapeno served with injera and hot green pepper chutney",
    price: "$20.95",
    type: "tibsentree",
  },
  {
    name: "Asa Dulet",
    description:
      "minced talapia sauteed with onion, garlic, tomatoes and our special sauce",
    price: "$20.95",
    type: "seafood",
  },
  {
    name: 'Kitfo. minced ground beef "Steak Tartare"',
    description:
      "clarified butter, mitmita,black cardamon. Raw| Meduium | Well",
    price: "$20.95",
    type: "tibsentree",
  },
  {
    name: "Miser Wot | Red Lentils",
    description: "",
    price: "$19.95",
    type: "veganentree",
  },
  {
    name: "Kitfo",
    description:
      "Minced ground beef mixed collard greens farmer cheese, onion, jalaleno. Raw | Medium | Well",
    price: "$21.95",
    type: "tibsentree",
  },
  {
    name: "Kik Wot | Yellow Lentils",
    description: "",
    price: "$19.95",
    type: "veganentree",
  },
  {
    name: "Tibs Firfir",
    description:
      "suatueed beef with onion, garlic, tomatoes and awaze chutney mixed in with dryed injera",
    price: "$19.95",
    type: "tibsentree",
  },
  {
    name: "Gomen | Collard Greens",
    description: "",
    price: "$19.95",
    type: "veganentree",
  },
  {
    name: "Chicken Tibs",
    description: "",
    price: "$18.99",
    type: "tibsentree",
  },
  {
    name: "Green Chicken",
    description: "",
    price: "$18.99",
    type: "tibsentree",
  },
  {
    name: "Ground Beef Pasta",
    description: "",
    price: "$18.95",
    type: "pasta",
  },
  {
    name: "Shiro | Split Peas",
    description: "",
    price: "$20.95",
    type: "veganentree",
  },
  {
    name: "Veggie Pasta",
    description: "",
    price: "$18.95",
    type: "pasta",
  },
  {
    name: "Defin Miser | Brown Lentils",
    description: "",
    price: "$19.95",
    type: "veganentree",
  },
  {
    name: "Asa Gulash Pasta",
    description: "",
    price: "$20.95",
    type: "pasta",
  },
  {
    name: "Atakilt Wot | Cabbage",
    description: "",
    price: "$19.95",
    type: "veganentree",
  },
  {
    name: "Key wot lamb",
    description: "",
    price: "$21.95",
    type: "Bone in lamb cooked in a richful spicy berber sauce",
  },
  {
    name: "Dinich Wot | Potato red stew",
    description: "",
    price: "$19.95",
    type: "veganentree",
  },
  {
    name: "Okra",
    description: "",
    price: "$16",
    type: "veganentree",
  },
  {
    name: "Dorho wot",
    description:
      "Slow cooked chicken in a spicy berbere saice served with 2 hard boiled egg",
    price: "$21.95",
    type: "tradstews",
  },
  {
    name: "Okra with meat",
    description: "",
    price: "$18.99",
    type: "veganentree",
  },
  {
    name: "Timatim fit fit | tomato stuffing",
    description: "",
    price: "$13.95",
    type: "veganentree",
  },
  {
    name: "Gomen besiga",
    description: "Collard greens and beef",
    price: "$20.95",
    type: "tradstews",
  },
  {
    name: "Tibs sandwich",
    description: "Spicy | mild",
    price: "$11.99",
    type: "sandwich",
  },
  {
    name: "Quanta firfir",
    description: "",
    price: "$20.95",
    type: "firfir",
  },
  {
    name: "Kitfo sandwich",
    description: "Raw | med | well",
    price: "$11.99",
    type: "sandwich",
  },
  {
    name: "Yetsom firfir",
    description: "Vegan version of a stuffing",
    price: "$19.95",
    type: "firfir",
  },
  {
    name: "Siega wot firfir",
    description: "",
    price: "$20.95",
    type: "firfir",
  },
];
