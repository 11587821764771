import React from "react";

export const Food = ({ food }) => {
  return (
    <div className="col-md-6 all-menu tradstews">
      <div className="single-menu">
        <div className="title-wrap d-flex justify-content-between">
          <h4>{food.name}</h4>
          <h4 className="price">{food.price}</h4>
        </div>
        <p>{food.description}</p>
      </div>
    </div>
  );
};
