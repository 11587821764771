import React from "react";
import { Link } from "react-router-dom";

export const AppHeader = () => {
  return (
    <header id="header" className="mx-auto">
      <div className="header-top">
        <div className="container">
          <div className="row justify-content-center">
            <div id="logo">
              <Link to="#home">
                <img src="img/habesha/Adulislog09-01.png" alt="" title="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container main-menu">
        <div className="row align-items-center justify-content-center d-flex">
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <li>
                <Link to="#home">Home</Link>
              </li>
              <li>
                <Link to="#about">About</Link>
              </li>
              <li>
                <Link to="#menu">Menu</Link>
              </li>
              <li>
                <Link to="#gallery">Gallery</Link>
              </li>

              <li>
                <Link to="#contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
