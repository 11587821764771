import React from "react";

export const Contact = () => {
  return (
    <section className="contact-page-area section-gap relative">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div id="contact" className="title text-center">
            <h1 className="mb-3">Contact Us</h1>
          </div>
        </div>
        <iframe
          title="Location of Addulis Restaurant Seattle"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2684.907380077076!2d-122.35810898465428!3d47.70563567919137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549017a6829e7c13%3A0x544e4ec0e2c45854!2sAdulis%20Eritrean%20and%20Ethiopian%20Restaurant!5e0!3m2!1sen!2set!4v1663703110729!5m2!1sen!2set"
          width={"100%"}
          height={350}
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="mb-10"
        ></iframe>

        <div className="row mt-3">
          <div className="col-lg-4 d-flex flex-column address-wrap">
            <div className="single-contact-address d-flex flex-row">
              <div className="icon">
                <span className="lnr lnr-home"></span>
              </div>
              <div className="contact-details">
                <h5>Seattle, WA</h5>
                <p>
                  10515 Greenwood Ave N, Seattle, Washington <br />
                </p>
              </div>
            </div>
            <div className="single-contact-address d-flex flex-row">
              <a href="tel:+1 206 920 5973" className="flex-row d-flex">
                <div className="icon">
                  <span className="lnr lnr-phone-handset"></span>
                </div>
                <div className="contact-details">
                  <h5>+1 206 920 5973</h5>
                </div>
              </a>
            </div>
            <div className="single-contact-address d-flex flex-row">
              <div className="icon">
                <span className="lnr lnr-envelope"></span>
              </div>
              <div className="contact-details">
                <h5>
                  <a href="mailto:info@adulisrestaurant.com">
                    info@adulisrestaurant.com
                  </a>
                </h5>
                <h5>
                  <a href="https://www.adulisrestaurant.com">
                    www.adulisrestaurant.com
                  </a>
                </h5>
                <p>Send us your query anytime!</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-8">
            <form
              className="form-area contact-form text-right"
              id="myForm"
              action="mail.php"
              method="post"
            >
              <div className="row">
                <div className="col-lg-6 form-group">
                  <input
                    name="name"
                    placeholder="Enter your name"
                    className="common-input mb-20 form-control"
                    required=""
                    type="text"
                  />

                  <input
                    name="email"
                    placeholder="Enter email address"
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                    className="common-input mb-20 form-control"
                    required=""
                    type="email"
                  />

                  <input
                    name="subject"
                    placeholder="Enter subject"
                    className="common-input mb-20 form-control"
                    required=""
                    type="text"
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <textarea
                    className="common-textarea form-control"
                    name="message"
                    placeholder="Enter Messege"
                    required=""
                  ></textarea>
                </div>
                <div className="col-lg-12">
                  <div
                    className="alert-msg"
                    style={{ textAlign: "left" }}
                  ></div>
                  <button
                    className="genric-btn primary"
                    style={{ float: "right" }}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </section>
  );
};
