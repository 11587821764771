import React from "react";

const About = () => {
  return (
    <section className="home-about-area section-gap my-5">
      <div className="container m-auto">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6 home-about-left">
            <h1 id="about">About Our Story</h1>
            <p>
              Adulis Restaurant is one of the best Ethiopian & Eritrean restaurants located
              in Seattle Washington, offering a fun-filled, casual dining
              experience! Adulis Restaurant strives always serving you fresh and
              affordable Ethiopian cuisine with a desire of quality ingredients.
            </p>
            <a href="#menu" className="primary-btn">
              view full menu
            </a>
          </div>
          <div className="col-lg-6 home-about-right">
            <img
              className="img-fluid about-image"
              src="img/habesha/photo_2022-08-28_12-02-323.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
