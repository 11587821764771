import React from "react";

export const Gallery = () => {
  return (
    <section className="gallery-area section-gap relative">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="menu-content col-lg-8">
            <div className="title text-center">
              <h1 id="gallery">Food and Customer Gallery</h1>
              <p></p>
            </div>
          </div>
        </div>

        <div className="gallery-content">
          <div className="row grid grid-gallery">
            <div className="col-lg-4 col-md-6 col-sm-6 all-photo">
              <div className="single-gallery">
                <img
                  src="img/habesha/photo_2022-08-28_12-02-21.jpg"
                  height="233.61"
                  width="349.98"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 all-photo">
              <div className="single-gallery">
                <img
                  src="img/habesha/photo_2022-08-28_12-02-24.jpg"
                  height="233.61"
                  width="349.98"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 all-photo">
              <div className="single-gallery">
                <img
                  src="img/habesha/photo_2022-08-28_12-02-36.jpg"
                  alt=""
                  height="233.61"
                  width="349.98"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 all-photo">
              <div className="single-gallery">
                <img
                  src="img/habesha/photo_2022-08-28_12-02-39.jpg"
                  height="233.61"
                  width="349.98"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 all-photo">
              <div className="single-gallery">
                <img
                  src="img/habesha/photo_2022-08-28_12-02-43.jpg"
                  height="233.61"
                  width="349.98"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 all-photo">
              <div className="single-gallery">
                <img
                  src="img/habesha/photo_2022-08-28_12-02-47.jpg"
                  height="233.61"
                  width="349.98"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
