import React from 'react';
import './App.css';
import { Contact } from './components/Contact';
import { Menu } from './components/Menu';
import { Gallery } from './components/Gallery';
import { Footer } from './components/Footer';
import About from './components/About';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppHeader } from './components/AppHeader';
import { Banner } from './components/Banner';
import { Reservation } from './components/Reservation';

function App() {
  return (
    <>
      <Router>
        <AppHeader />
        <Banner />
        <About />
        <Reservation />
        <Menu />
        <Gallery />
        <Contact />
        <Footer />
      </Router>
    </>
  );
}

export default App;