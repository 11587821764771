import React from "react";

export const Banner = () => {
  return (
    <section id="home" className="banner-area">
      <div className="container">
        <div className="row fullscreen align-items-center justify-content-between">
          <div className="col-lg-12 banner-content">
            <h1 className="text-white text-center">Adulis Restaurant</h1>
            <h6 className="text-white text-center">
              Best Ethiopian & Eritrean Restaurant
            </h6>{" "}
            <br />
            <div class=" row d-flex justify-content-center align-content-center ">
              <a href="#menu" className="primary-btn text-uppercase">
                Check Our Menu
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
